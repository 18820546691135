import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eventThrow: null,
    sliderValues: []
};

export const eventHandlerSlice = createSlice({
    name: 'eventHandler',
    initialState,
    reducers: {
        setEventThrow: (state, action) => {
            state.eventThrow = action.payload;
        },
        setSliderValues: (state, action) => {
            state.sliderValues = action.payload;
        }
    }
});

export const { setEventThrow, setSliderValues } = eventHandlerSlice.actions;
