export const Y_AXIS = {
    tickCount: 10,
    max: 100,
    min: 0
};

export const X_AXIS = {
    tickCount: 12
};

export const GRADIENT_CHART_CONFIG = {
    xAxis: X_AXIS,
    yAxis: {
        ...Y_AXIS,
        grid: {
            line: {
                style: {
                    lineWidth: 0
                }
            }
        }
    },
    padding: [12, 15, 25, 28]
};

export const OXYGEN_CHART_CONFIG = {
    yAxis: {
        tickCount: 10,
        max: 130,
        min: 0,
        grid: {
            line: {
                style: {
                    lineWidth: 0
                }
            }
        }
    },
    xAxis: {
        ...X_AXIS,
        position: 'top'
    },
    reflect: 'y',
    padding: [12, 15, 25, 28]
};
