import { configureStore } from '@reduxjs/toolkit';
import {
    dailyCountSlice,
    dailyReportSlice,
    engineServiceBoatSelectionSlice,
    eventChartSlice,
    eventDetailSlice,
    eventHandlerSlice,
    eventListSlice,
    farmerCompanySelectionSlice,
    farmerPenSelectionSlice,
    farmerSiteSelectionSlice,
    filterSlice,
    originalBoatSelectionSlice,
    profileSlice,
    serviceBoatDetailSlice,
    serviceBoatListSlice,
    serviceBoatSelectionSlice,
    serviceBoatSlice,
    setupAccountSlice,
    stressDetectionFarmerCompanySelectionSlice,
    stressDetectionFarmerSiteSelectionSlice,
    stressDetectionServiceBoatSelectionSlice,
    stressDetectionStartStopSlice,
    stressDetectionStatusSlice,
    tenantSlice,
    userSlice
} from './slices';

const store = configureStore({
    reducer: {
        tenant: tenantSlice.reducer,
        setupAccount: setupAccountSlice.reducer,
        engineServiceBoatSelection: engineServiceBoatSelectionSlice.reducer,
        serviceBoatSelection: serviceBoatSelectionSlice.reducer,
        originalBoatSelection: originalBoatSelectionSlice.reducer,
        farmerCompanySelection: farmerCompanySelectionSlice.reducer,
        farmerSiteSelection: farmerSiteSelectionSlice.reducer,
        farmerPenSelection: farmerPenSelectionSlice.reducer,
        profile: profileSlice.reducer,
        serviceBoat: serviceBoatSlice.reducer,
        serviceBoatList: serviceBoatListSlice.reducer,
        serviceBoatDetail: serviceBoatDetailSlice.reducer,
        dailyReport: dailyReportSlice.reducer,
        dailyCount: dailyCountSlice.reducer,
        eventChart: eventChartSlice.reducer,
        filter: filterSlice.reducer,
        eventList: eventListSlice.reducer,
        eventDetail: eventDetailSlice.reducer,
        stressDetectionFarmerCompanySelection: stressDetectionFarmerCompanySelectionSlice.reducer,
        stressDetectionFarmerSiteSelection: stressDetectionFarmerSiteSelectionSlice.reducer,
        stressDetectionServiceBoatSelection: stressDetectionServiceBoatSelectionSlice.reducer,
        stressDetectionStartStop: stressDetectionStartStopSlice.reducer,
        stressDetectionStatus: stressDetectionStatusSlice.reducer,
        user: userSlice.reducer,

        eventHandler: eventHandlerSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false // Disable serializable check
        })
});

export default store;
