import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import CrowdingArea from './CrowdingArea';
import CurrentSpeed from './CurrentSpeed';
import Efficiency from './Efficiency';
import EventInfo from './EventInfo';
import OxygenLevel from './OxygenLevel';
import SliderFilter from './SliderFilter';
import StressLevel from './StressLevel';
import StressZone from './StressZone';
import Temperature from './Temperature';

const EventData = () => {
    const selectedEventId = useSelector((s) => s.filter.data.selectedEventId);
    const eventListLoading = useSelector((s) => s.eventList.loading);

    if (!selectedEventId) return;

    return (
        <div className="event-detail mt-2">
            <Spin spinning={eventListLoading}>
                <EventInfo />

                <SliderFilter />

                <Row gutter={8}>
                    <Col xs={24} xl={12}>
                        <StressLevel />
                    </Col>

                    <Col xs={24} xl={12}>
                        <StressZone />
                    </Col>

                    <Col xs={24} xl={12}>
                        <OxygenLevel />
                    </Col>

                    <Col xs={24} xl={12}>
                        <Efficiency />
                    </Col>

                    <Col xs={24} xl={12}>
                        <Temperature />
                    </Col>

                    <Col xs={24} xl={12}>
                        <CurrentSpeed />
                    </Col>

                    <Col xs={24} xl={12}>
                        <CrowdingArea />
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default EventData;
