export const STRESS_DETECTION_ENGINE_BASE_URL_KEY = 'STRESS_DETECTION_ENGINE_BASE_URL';

export const STRESS_ZONES = [
    {
        name: 'stressZone.extremelyUnacceptable',
        min: 81,
        max: 100
    },
    {
        name: 'stressZone.unacceptable',
        min: 61,
        max: 80
    },
    {
        name: 'stressZone.unwanted',
        min: 41,
        max: 60
    },
    {
        name: 'stressZone.acceptable',
        min: 21,
        max: 40
    },
    {
        name: 'stressZone.wanted',
        min: 0,
        max: 20
    }
];
