import { Button, Card, Col, Row, Spin } from 'antd';
import {
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    NORWEGIAN_FULL_TIME_FORMAT
} from 'common/constants';
import { calculateTimeDifference, formatDateTime } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setEventThrow } from 'redux/slices/event/eventHandlerSlice';

const EventInfo = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const eventThrow = useSelector((s) => s.eventHandler.eventThrow);

    const { data: eventInfo, loading } = useSelector((s) => s.eventDetail.info);

    const infoList = [
        {
            key: 'eventDetail.location',
            value: `${eventInfo.siteName ?? ''} - ${eventInfo.penNumber ?? ''}`,
            col: { xs: 12, xl: 6, xxl: 4 }
        },
        { key: 'eventDetail.boatName', value: eventInfo.boatName, col: { xs: 12, xl: 6, xxl: 4 } },
        {
            key: 'eventDetail.eventType',
            value: eventInfo.eventType,
            col: { xs: 12, xl: 6, xxl: 4 }
        },
        {
            key: 'eventDetail.time',
            value: (
                <>
                    <span>{formatDateTime(eventInfo.startTime, NORWEGIAN_FULL_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(eventInfo.stopTime, NORWEGIAN_FULL_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'eventDetail.actualCrowdingTime',
            value: calculateTimeDifference(eventInfo.startTime, eventInfo.stopTime),
            col: { xs: 12, xl: 6, xxl: 6 }
        }
    ];

    useEffect(() => {
        dispatch(setEventThrow(null));
    }, [eventInfo.id]);

    return (
        <Card className="event-info">
            <Spin spinning={loading}>
                <Row className="text-base" gutter={8}>
                    {infoList.map((info) => (
                        <Col key={info.key} {...info.col} className="flex flex-col mb-2">
                            <span className="font-semibold">{t(info.key)}</span>
                            <span>{info.value}</span>
                        </Col>
                    ))}
                </Row>

                <div className="w-full flex justify-start flex-wrap">
                    <Button
                        className={`${
                            eventThrow ? BUTTON_GRAY_UN_SELECTED : BUTTON_GRAY_SELECTED
                        } mr-4 mt-4 w-20`}
                        onClick={() => dispatch(setEventThrow(null))}
                    >
                        {t('eventDetail.overall')}
                    </Button>

                    {eventInfo.throws?.map((throwItem) => (
                        <Button
                            className={`${
                                eventThrow?.id === throwItem.id
                                    ? BUTTON_GRAY_SELECTED
                                    : BUTTON_GRAY_UN_SELECTED
                            } mr-4 mt-4 w-20`}
                            onClick={() => dispatch(setEventThrow(throwItem))}
                            key={throwItem.id}
                        >
                            {t('eventDetail.throw')} {throwItem.throwName}
                        </Button>
                    ))}
                </div>
            </Spin>
        </Card>
    );
};

export default EventInfo;
