import { Card, Spin } from 'antd';
import { SBLine } from 'common/components';
import { BLUE, LIGHT_GREEN, LIGHT_ORANGE, LIGHT_RED, LIGHT_YELLOW } from 'common/constants';
import {
    formatSeconds,
    generateGradientColor,
    getDifferenceInSeconds,
    isTimeWithinSliderValues
} from 'common/utils';
import { reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GRADIENT_CHART_CONFIG } from '../chartConfig';

const StressLevel = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const { data: eventHabitat, loading } = useSelector((s) => s.eventDetail.habitat);

    const filteredData = reduce(
        eventHabitat.stressLevels,
        (accumulator, stress) => {
            const differenceInSeconds = getDifferenceInSeconds(
                eventHabitat.startTime,
                stress.stop_time
            );

            if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                accumulator.push({
                    time: formatSeconds(differenceInSeconds),
                    value: stress.level
                });

            return accumulator;
        },
        []
    );

    return (
        <Card className="mt-2">
            <Spin spinning={loading}>
                <p className="font-medium text-base">{t('eventDetail.stressLevel')}</p>
                <SBLine
                    data={filteredData}
                    height={300}
                    isSingleLine={true}
                    color={BLUE}
                    chartConfig={GRADIENT_CHART_CONFIG}
                    style={{
                        background: generateGradientColor({
                            colors: [LIGHT_RED, LIGHT_ORANGE, LIGHT_YELLOW, LIGHT_GREEN]
                        })
                    }}
                />
            </Spin>
        </Card>
    );
};

export default StressLevel;
