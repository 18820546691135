import { createAsyncThunk } from '@reduxjs/toolkit';
import { EVENT_API, EVENT_LIST_API } from 'common/constants';
import { API } from 'services';

export const getEventList = createAsyncThunk(
    'event/getEventList',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.post(EVENT_LIST_API, {
                ...filters,
                farmerSites: filters.farmerSites.map((site) => site.selectionObject),
                farmerPens: filters.farmerPens.map((pen) => pen.selectionObject)
            });
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventInfo = createAsyncThunk(
    'events/getEventInfo',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${EVENT_API}/${id}/info`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventHabitat = createAsyncThunk(
    'events/getEventHabitat',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${EVENT_API}/${id}/habitat`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventChannels = createAsyncThunk(
    'events/getEventChannels',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${EVENT_API}/${id}/channels`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
