import { createSlice } from '@reduxjs/toolkit';
import { getEventChannels, getEventHabitat, getEventInfo } from 'redux/thunks/eventThunk';

const initialState = {
    info: {
        data: {},
        loading: false
    },
    habitat: {
        data: {},
        loading: false
    },
    channels: {
        data: {},
        loading: false
    },
    loading: false
};

export const eventDetailSlice = createSlice({
    name: 'eventDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEventInfo.pending, (state) => {
                state.info.loading = true;
            })
            .addCase(getEventInfo.fulfilled, (state, action) => {
                state.info.loading = false;
                state.info.data = action.payload || initialState.info.data;
            })
            .addCase(getEventInfo.rejected, (state) => {
                state.info.loading = false;
                state.info.data = initialState.info.data;
            })

            .addCase(getEventHabitat.pending, (state) => {
                state.habitat.loading = true;
            })
            .addCase(getEventHabitat.fulfilled, (state, action) => {
                state.habitat.loading = false;
                state.habitat.data = action.payload || initialState.habitat.data;
            })
            .addCase(getEventHabitat.rejected, (state) => {
                state.habitat.loading = false;
                state.habitat.data = initialState.habitat.data;
            })

            .addCase(getEventChannels.pending, (state) => {
                state.channels.loading = true;
            })
            .addCase(getEventChannels.fulfilled, (state, action) => {
                state.channels.loading = false;
                state.channels.data = action.payload || initialState.channels.data;
            })
            .addCase(getEventChannels.rejected, (state) => {
                state.channels.loading = false;
                state.channels.data = initialState.channels.data;
            });
    }
});
