import { Card } from 'antd';
import { SBLine } from 'common/components';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { flatMap, reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { X_AXIS } from '../chartConfig';
import { Spin } from 'antd';

const CurrentSpeed = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const { data: eventChannels, loading } = useSelector((s) => s.eventDetail.channels);

    const filteredData = flatMap(eventChannels.channels, (channel) =>
        reduce(
            channel.currentSpeeds,
            (accumulator, speed) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    eventChannels.startTime,
                    speed.stop_time
                );

                if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                    accumulator.push({
                        type: `${t('eventDetail.channel')} ${channel.channelName}`,
                        time: formatSeconds(differenceInSeconds),
                        value: speed.value || 0
                    });

                return accumulator;
            },
            []
        )
    );

    return (
        <Card className="mt-2">
            <Spin spinning={loading}>
                <p className="font-medium text-base">{t('eventDetail.currentSpeed')}</p>
                <SBLine data={filteredData} height={300} chartConfig={{ xAxis: X_AXIS }} />
            </Spin>
        </Card>
    );
};

export default CurrentSpeed;
