import EventData from './EventData';
import FilterEvents from './FilterEvents';

const Event = () => {
    return (
        <>
            <FilterEvents />

            <EventData />
        </>
    );
};

export default Event;
