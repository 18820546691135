import { Card, Col, Row, Spin } from 'antd';
import { SBMultipleSelection, SBRangeDate } from 'common/components';
import { BUTTON_BLUE_CLASS, NORWEGIAN_DATE_FORMAT } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    getEventChannels,
    getEventHabitat,
    getEventInfo,
    getEventList,
    getFarmerCompaniesSelection,
    getFarmerPensSelection,
    getFarmerSitesSelection,
    getServiceBoatsSelection,
    updateFilters
} from 'redux/thunks';
import './FilterEvents.scss';

const FilterEvents = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();

    const allPens = useSelector((s) => s.farmerPenSelection.data);
    const allSites = useSelector((s) => s.farmerSiteSelection.data);
    const companiesSelection = useSelector((s) => s.farmerCompanySelection.data);
    const serviceBoatsSelection = useSelector((s) => s.serviceBoatSelection.data);
    const { data: events = [], loading = false } = useSelector((s) => s.eventList);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [sitesSelection, setSitesSelection] = useState([]);
    const [filters, setFilters] = useState({
        farmerOrganizationIds: initialFilters.farmerOrganizationIds,
        farmerSites: initialFilters.farmerSites,
        farmerPens: initialFilters.farmerPens,
        serviceBoatIds: initialFilters.serviceBoatIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    const handleGetStressEventDetail = (id) => {
        dispatch(getEventInfo(id));
        dispatch(getEventChannels(id));
        dispatch(getEventHabitat(id));
    };

    useEffect(() => {
        dispatch(getFarmerCompaniesSelection());
        dispatch(getFarmerSitesSelection());
        dispatch(getFarmerPensSelection());
        dispatch(getServiceBoatsSelection());
        dispatch(getEventList(filters));
    }, []);

    useEffect(() => {
        setPensSelection(allPens);
    }, [allPens]);

    useEffect(() => {
        setSitesSelection(allSites);
    }, [allSites]);

    useEffect(() => {
        if (events.some((e) => e.id === initialFilters.selectedEventId)) {
            if (location.state?.navigatedEventId) {
                location.state.navigatedEventId = null;
            }
            handleGetStressEventDetail(initialFilters.selectedEventId);
            return;
        }

        if (!location.state?.navigatedEventId) {
            handleEventChange(events[0]?.id || null);
        }
    }, [events]);

    const handleCompaniesChange = (farmerOrganizationIds) => {
        let newSitesSelections = allSites;
        let selectedSites = filters.farmerSites;

        let newPensSelections = allPens;
        let selectedPens = filters.farmerPens;

        if (farmerOrganizationIds.length) {
            newSitesSelections = allSites.filter((site) =>
                farmerOrganizationIds.includes(site.selectionObject.organizationId)
            );

            selectedSites = selectedSites.filter((site) =>
                newSitesSelections.some((siteSelection) => siteSelection.id === site.id)
            );

            if (selectedSites.length) {
                newPensSelections = allPens.filter((pen) =>
                    selectedSites.some(
                        (site) =>
                            site.selectionObject.localityNumber ===
                                pen.selectionObject.localityNumber &&
                            site.selectionObject.organizationId ===
                                pen.selectionObject.organizationId
                    )
                );
            } else {
                newPensSelections = allPens.filter((pen) =>
                    farmerOrganizationIds.includes(pen.selectionObject.organizationId)
                );
            }

            selectedPens = selectedPens.filter((pen) =>
                newPensSelections.some((penSelection) => penSelection.id === pen.id)
            );
        }

        setSitesSelection(newSitesSelections);
        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            farmerOrganizationIds: farmerOrganizationIds,
            farmerSites: selectedSites,
            farmerPens: selectedPens
        });
    };

    const handleSitesChange = (siteIds, sites) => {
        let selectedPens = filters.farmerPens;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) =>
                sites.some(
                    (site) =>
                        site.selectionObject.localityNumber ===
                            pen.selectionObject.localityNumber &&
                        site.selectionObject.organizationId === pen.selectionObject.organizationId
                )
            );

            selectedPens = selectedPens.filter((pen) =>
                newPensSelections.some((penSelection) => penSelection.id === pen.id)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            farmerSites: sites,
            farmerPens: selectedPens
        });
    };

    const handlePensChange = (penIds, pens) => {
        setFilters({
            ...filters,
            farmerPens: pens
        });
    };

    const handleServiceBoatsChange = (serviceBoatIds) => {
        setFilters({
            ...filters,
            serviceBoatIds: serviceBoatIds
        });
    };

    const handleEventChange = (eventId) => {
        if (eventId) {
            handleGetStressEventDetail(eventId);
        }

        dispatch(
            updateFilters({
                ...initialFilters,
                selectedEventId: eventId
            })
        );
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        dispatch(getEventList(filters));
        dispatch(
            updateFilters({
                ...initialFilters,
                farmerOrganizationIds: filters.farmerOrganizationIds,
                farmerSites: filters.farmerSites,
                farmerPens: filters.farmerPens,
                serviceBoatIds: filters.serviceBoatIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );
    };

    return (
        <div>
            <div className="event-filter-bar h-full flex flex-col">
                <h2 className="mb-2">{t('filterBar.title')}</h2>
                <div className="filter md:text-base rounded-lg p-2 pb-5 flex flex-wrap">
                    <div className="filter-bar flex-grow">
                        <Row>
                            <Col xs={24} md={12} xl={12} xxl={6}>
                                <div className="font-semibold">
                                    {t('filterBar.farmerCompany.title')}
                                </div>
                                <SBMultipleSelection
                                    placeholder={t('filterBar.farmerCompany.placeholder')}
                                    listSelectItem={companiesSelection}
                                    onChange={handleCompaniesChange}
                                    value={filters.farmerOrganizationIds}
                                />
                            </Col>

                            <Col xs={24} md={12} xl={12} xxl={6}>
                                <div className="font-semibold">
                                    {t('filterBar.farmerSite.title')}
                                </div>
                                <SBMultipleSelection
                                    placeholder={t('filterBar.farmerSite.placeholder')}
                                    listSelectItem={sitesSelection}
                                    onChange={handleSitesChange}
                                    value={filters.farmerSites.map((site) => site.id)}
                                />
                            </Col>

                            <Col xs={24} md={12} xl={12} xxl={6}>
                                <div className="font-semibold">
                                    {t('filterBar.farmerPen.title')}
                                </div>
                                <SBMultipleSelection
                                    placeholder={t('filterBar.farmerPen.placeholder')}
                                    listSelectItem={pensSelection}
                                    onChange={handlePensChange}
                                    value={filters.farmerPens.map((pen) => pen.id)}
                                />
                            </Col>

                            <Col xs={24} md={12} xl={12} xxl={6}>
                                <div className="font-semibold">
                                    {t('filterBar.serviceBoat.title')}
                                </div>
                                <SBMultipleSelection
                                    placeholder={t('filterBar.serviceBoat.placeholder')}
                                    listSelectItem={serviceBoatsSelection}
                                    onChange={handleServiceBoatsChange}
                                    value={filters.serviceBoatIds}
                                />
                            </Col>

                            <Col xs={24} md={12} xl={12} xxl={7}>
                                <div className="font-semibold">
                                    {t('filterBar.rangeDate.title')}
                                </div>
                                <SBRangeDate
                                    placeholder={[
                                        t('filterBar.rangeDate.startDatePlaceholder'),
                                        t('filterBar.rangeDate.endDatePlaceholder')
                                    ]}
                                    onChange={handleRangeDateChange}
                                    value={[filters.fromDate, filters.toDate]}
                                />
                            </Col>

                            <Col className="flex items-end p-2">
                                <button
                                    className={`${BUTTON_BLUE_CLASS} !m-0`}
                                    onClick={handleFilterSubmit}
                                >
                                    {t('filterBar.apply')}
                                </button>
                            </Col>
                        </Row>
                    </div>

                    <div className="events min-w-[200px] pt-2.5 pr-2.5 pl-2.5 flex-grow xl:grow-0">
                        <div className="font-semibold">{t('eventDetail.events')}</div>
                        <Card className="h-[109px]">
                            <Spin spinning={loading}>
                                {!(events.length || loading) ? (
                                    <p className="m-0 text-center">
                                        {t('eventDetail.noEventsFound')}
                                    </p>
                                ) : (
                                    <div className="max-h-[90px] overflow-y-auto overflow-x-hidden">
                                        <table className="whitespace-nowrap">
                                            <tbody>
                                                {events.map((event) => (
                                                    <tr
                                                        key={event.id}
                                                        className={`cursor-pointer hover:font-medium transition px-2 
                                                            ${
                                                                event.id ===
                                                                    initialFilters.selectedEventId &&
                                                                'bg-sky-700 text-white font-medium'
                                                            }`}
                                                        onClick={() => handleEventChange(event.id)}
                                                    >
                                                        <td>
                                                            {formatDateTime(
                                                                event.date,
                                                                NORWEGIAN_DATE_FORMAT
                                                            )}
                                                        </td>
                                                        <td>{event.siteName}</td>
                                                        <td>{event.penNumber}</td>
                                                        <td>{event.eventType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </Spin>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterEvents;
