import { Spin } from 'antd';
import { Card } from 'antd';
import { SBBar } from 'common/components';
import { GRAY, GREEN, ORANGE, RED, STRESS_ZONES, YELLOW } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { map, reduce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StressZone = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const eventInfo = useSelector((s) => s.eventDetail.info.data);
    const { data: eventHabitat, loading } = useSelector((s) => s.eventDetail.habitat);

    const eventThrowTimes = eventInfo.throws?.map((eventThrow) => ({
        startTime: getDifferenceInSeconds(eventInfo.startTime, eventThrow.startTime),
        stopTime: getDifferenceInSeconds(eventInfo.startTime, eventThrow.stopTime)
    }));

    const isTimeWithinEventThrowTimes = (seconds) =>
        isEmpty(eventThrowTimes) ||
        eventThrowTimes.some(
            (eventThrow) => eventThrow.startTime <= seconds && seconds <= eventThrow.stopTime
        );

    const filteredStressLevels = reduce(
        eventHabitat.stressLevels,
        (accumulator, stress) => {
            const differenceInSeconds = getDifferenceInSeconds(
                eventHabitat.startTime,
                stress.stop_time
            );

            if (
                isTimeWithinSliderValues(sliderValues, differenceInSeconds) &&
                isTimeWithinEventThrowTimes(differenceInSeconds)
            )
                accumulator.push({
                    totalSeconds: getDifferenceInSeconds(stress.start_time, stress.stop_time),
                    value: stress.level
                });

            return accumulator;
        },
        []
    );

    const filteredData = map(STRESS_ZONES, (stressZone) => ({
        name: t(stressZone.name),
        value: filteredStressLevels
            .filter(
                (stressLevel) =>
                    stressZone.min <= stressLevel.value && stressLevel.value <= stressZone.max
            )
            .reduce((accumulator, stressLevel) => accumulator + stressLevel.totalSeconds, 0)
    }));

    return (
        <Card className="mt-2">
            <Spin spinning={loading}>
                <p className="font-medium text-base">{t('eventDetail.stressZone')}</p>
                <SBBar
                    data={filteredData}
                    colors={[GRAY, RED, ORANGE, YELLOW, GREEN]}
                    height={300}
                    formatValue={formatSeconds}
                />
            </Spin>
        </Card>
    );
};

export default StressZone;
